import React, { useEffect, useState } from 'react';
import axios from 'axios';

// const API_URL = 'http://localhost:3300/api';
const API_URL = 'https://bnagvip.lt365vi.com/api';

const formatDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  const day = String(dateTime.getDate()).padStart(2, '0');
  const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}`;
};

const App = () => {
  const [data, setData] = useState(null);
  const [dataStock, setDataStock] = useState([]);
  const [rewardResult, setRewardResult] = useState(null);
  const [rewardHistoryResult, setRewardHistoryResult] = useState(null);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [currentDate, setCurrentDate] = useState('');

  const fetchLotteryRewardResult = async () => {
    try {
      const requestBody = {
        lotteryId: '6505f94a35d1e4e1ae1ae3b9',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-stock`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        // console.log('response.data : ', response.data);
        // console.log('response.data.results : ', response.data.results.dateString);
        setData(response.data.results);
        setDataStock(response.data.results.stocks);
        setRewardResult(response.data.lotteryRewardResult);
        setTimeOfResultShow(response.data.timeOfResultShow);
        setRewardHistoryResult(response.data.historyReward);
        setCurrentDate(formatDate(response.data.results.dateString));

        // return response.data.results;
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    fetchLotteryRewardResult();

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult();
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, []);

  const mapStockName = (originalName) => {
    const nameMappings = {
      KTOP30: 'KTOP 30',
      KOSPI: 'KOSPI',
      KOSPI200: 'KOSPI 200',
      KOSDAQ: 'KOSDAQ',
      KRX300: 'KRX 300',
    };

    return nameMappings[originalName] || originalName;
  };

  const formatMoney = (number, decimalPlaces = 2) => {
    const fixedNumber = number.toFixed(decimalPlaces);
    const [integerPart, decimalPart] = fixedNumber.split('.');
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formattedNumber = `${integerWithCommas}.${decimalPart}`;

    return formattedNumber;
  };

  return (
    <div className='container-fluid px-0'>
      <div className='row mx-0'>
        <div className='col-12 col-xl-8 offset-xl-2 px-0'>
          <div id='krindex'>
            <div className='header'>
              <img src='assets/img/kr-header.png' />
            </div>
            <div className='content'>
              <div className='site-title-context'>
                <div className='title-krx'>
                  Korea Exchange
                  <div className='rew'>RENEWED</div>
                </div>
                <div className='title-gap' />
                <div>KRX TAKES A QUANTUM LEAP TOWARD A WORLD-CLASS MARKET</div>
              </div>
              <div className='nav'>
                <ul className='move-button'>
                  <li>
                    <button type='button'>
                      <span />
                    </button>
                  </li>
                  <li>
                    <button type='button'>
                      <span className='b2' />
                    </button>
                  </li>
                  <li>
                    <button type='button'>
                      <span className='b3' />
                    </button>
                  </li>
                  <li>
                    <button type='button'>
                      <span className='b4' />
                    </button>
                  </li>
                  <li>
                    <button type='button'>
                      <span className='b5' />
                    </button>
                  </li>
                  <li>
                    <button type='button'>
                      <span className='b6' />
                    </button>
                  </li>
                </ul>
              </div>
              <div className='intro-index-wrap'>
                <div className='intro-index'>
                  <ul>
                    {dataStock.map((item) => (
                      <li key={item._id}>
                        <span className='index-name'>
                          {mapStockName(item.stockName)}
                        </span>
                        <br className='no-mobile' />
                        <span className='index-price'>
                          {formatMoney(item.priceData[0].price)}
                        </span>
                        <br className='no-mobile' />
                        <span
                          className={
                            item.priceData[0].status === 'up'
                              ? 'index-up'
                              : 'index-down'
                          }
                        >
                          {item.priceData[0].status === 'up' ? '▲' : '▼'}{' '}
                          {item.priceData[0].diff} ({item.priceData[0].per})
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='today-result'>
                  <div>
                    <table className='table table-bordered'>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 14,
                              fontWeight: 700,
                              color: '#ffffff',
                              // textDecoration: 'underline',
                            }}
                          >
                            {currentDate}
                          </td>
                          <td>บน</td>
                          <td
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: '#ffffff',
                              textDecoration: 'underline',
                            }}
                          >
                            {rewardResult?.Evening.digits3}
                          </td>
                          <td>ล่าง</td>
                          <td
                            style={{
                              fontSize: 16,
                              fontWeight: 700,
                              color: '#ffffff',
                              textDecoration: 'underline',
                            }}
                          >
                            {rewardResult?.Evening.digits2}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='history'>
                <h3>ผลย้อนหลัง</h3>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th rowSpan={2}>วันที่</th>
                      <th colSpan={2}>ผลย้อนหลัง</th>
                    </tr>
                    <tr>
                      <th>บน</th>
                      <th>ล่าง</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardHistoryResult?.map((item) => (
                      <tr key={item._id}>
                        <td>{formatDate(item.dateString)}</td>
                        <td>
                          {timeOfResultShow && timeOfResultShow['Morning']
                            ? item.morningDigits3
                            : item.eveningDigits3}
                        </td>
                        <td>
                          {timeOfResultShow && timeOfResultShow['Morning']
                            ? item.morningDigits2
                            : item.eveningDigits2}
                        </td>
                        {/* <td>{item.eveningDigits3}</td>
                        <td>{item.eveningDigits2}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='kr-final-footer' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
